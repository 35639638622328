<template>
  <div class="container" :style="{ backgroundImage: 'url(' + bgUrl + ')' }">
    <div class="box">
      <div class="title1" v-if="queryForm.matchTypeName === '全国总决赛（有组别）'">
        {{ queryForm.matchTypeName }} - {{ queryForm.groupName }}
      </div>
      <div class="title1" v-else-if="queryForm.matchTypeName === '全国总决赛（无组别）'">
        {{ queryForm.matchTypeName }}
      </div>
      <div class="title1" v-else>
        {{ queryForm.matchTypeName }} - {{ queryForm.groupName }}
        <!-- {{ matchTypeName }} - {{ districtName }} - {{ groupName }} -->
      </div>
      <div class="title2">签到现场</div>
      <el-autocomplete
        class="input"
        v-model="jsonStr"
        ref="input"
        :fetch-suggestions="querySearch"
        placeholder="请输入手机号后四位/或扫描签到二维码"
        :trigger-on-focus="false"
        @keyup.enter.native="signIn"
        @select="handleSelect"
      ></el-autocomplete>
      <div class="title3">请将小程序签到二维码扣在扫码器上</div>
    </div>
    <!-- dialog -->
    <el-dialog title="" :visible.sync="dialogVisible" width="30%">
      <div>
        <div class="title4">签到成功</div>
        <el-form style="margin-top: 15px" :model="data" label-width="90px">
          <el-form-item label="照片：" v-if="data.oneInchPhoto !== ''">
            <el-image
              style="width: 150px; height: 150px"
              :src="
                data.oneInchPhoto === ''
                  ? 'https://cdn.spbcn.org/spbcnappneed/userHeadPlaceImage1.png'
                  : data.oneInchPhoto
              "
              fit="contain"
            ></el-image>
          </el-form-item>
          <el-form-item label="姓名：">
            <div style="font-size: 20px">{{ data.userName }}</div>
          </el-form-item>
          <el-form-item label="手机：">
            <div style="font-size: 20px">{{ data.phone }}</div>
          </el-form-item>
          <el-form-item label="学校：">
            <div style="font-size: 20px">{{ data.schoolName }}</div>
          </el-form-item>
          <el-form-item label="年级：">
            <div style="font-size: 20px">{{ data.gradeName }}</div>
          </el-form-item>
          <el-form-item label="证件类型：">
            <div style="font-size: 20px">
              {{ data.cardType === "0" ? "身份证" : "护照" }}
            </div>
          </el-form-item>
          <el-form-item label="证件号：">
            <div style="font-size: 20px">{{ data.idCard }}</div>
          </el-form-item>
          <el-form-item label="比赛号码：">
            <span class="matchNum">{{ data.competitionNumber }}</span>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import { citySignIn } from '@/http/api'
export default {
  data () {
    return {
      signInData: [],
      queryForm: JSON.parse(this.$route.query.queryForm),
      name: window.sessionStorage.getItem('username'),
      jsonStr: '',
      data: {},
      numPool: [], // 号码池
      dialogVisible: false,
      dict_grade: this.$userInfo.getGradeData(),
      dataId: ''
    }
  },
  created () {
    this.signInData = JSON.parse(window.localStorage.getItem(this.queryForm.raceSchedule + this.queryForm.districtId + this.queryForm.level + 'signInData'))
    this.numPool = JSON.parse(window.localStorage.getItem(this.queryForm.raceSchedule + this.queryForm.districtId + this.queryForm.level + 'numPool'))
    this.$nextTick(() => { // 让input永远获得焦点，用于连续扫码
      this.$refs.input.focus()
    })
  },
  methods: {
    signIn (flag) {
      var phone = '不可能的手机号' // 由于手机号可能为空，所以要设置一个不可能的手机号
      if (this.dataId === '') { // 不使用模糊查询时去匹配手机号
        try {
          console.log('this.jsonStr', this.jsonStr)
          var tempData = JSON.parse(this.jsonStr)
          phone = tempData.userPhone
        } catch (error) {
          return this.$message.error('二维码错误，请扫码有效二维码')
        }
      }
      for (let index = 0; index < this.signInData.length; index++) {
        const element = this.signInData[index]
        if (element.phone === phone || element.id === this.dataId) { // 用手机号匹配或者id匹配
          this.data = element
          this.dataFormatter(this.data) // 处理数据展示形式
          if (flag === 'select') {
            this.$confirm('签到用户为：' + element.userName + '-' + element.schoolName + '-' + element.phone + ', 请确认签到?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              element.signIn = true
              this.signInDataRequest(element, index)
              //
            }).catch(() => {
            })
          } else {
            element.signIn = true
            this.signInDataRequest(element, index)
          }
        }
      }
      // 初始化数据
      this.jsonStr = ''
      this.dataId = ''
    },
    signInDataRequest (element, index) {
      this.signInData[index] = element
      citySignIn(element).then((res) => {
        this.dialogVisible = true
      }).catch((err) => {
        console.log('err', err)
      })
    },
    dataFormatter (data) {
      if (data.sex === 0) {
        data.genderName = '女'
      } else {
        data.genderName = '男'
      }
      this.dict_grade.forEach(element => {
        if (element.dictValue === data.grade) {
          data.gradeName = element.dictLabel
        }
      })
    },
    querySearch (queryString, cb) {
      var signInData = this.signInData
      var results = queryString ? signInData.filter(this.createFilter(queryString)) : signInData
      results.forEach(element => {
        element.value = element.userName + '-' + element.schoolName + '-' + element.phone
      })
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter (queryString) {
      return (item) => {
        if (item.phone.indexOf(queryString) !== -1 || item.userName.indexOf(queryString) !== -1) {
          if (!item.signIn) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      }
    },
    handleSelect (item) {
      this.dataId = item.id
      this.signIn('select')
    }
  },
  computed: {
    bgUrl () {
      console.log('this.queryForm', this.queryForm)
      switch (this.queryForm.raceSchedule) {
        case '4': // 城市半决赛
          return 'http://cdn.spbcn.org/spbcnWeb/pic/sspcn_signIn_city_semifinals_bg_2023.png'
        case '5': // 城市决赛
          return 'http://cdn.spbcn.org/spbcnWeb/pic/sspcn_signIn_city_finals_bg_2023.png'
        case '8': // 全国总决赛（有组别）
        case '7': // 全国总决赛（无组别）
          return 'http://cdn.spbcn.org/spbcnWeb/pic/sspcn_signIn_national_finals_bg_2022.png'
      }
      return ''
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  background-size: cover;
  background-position: center;
  height: 100%;
}

.top_pic {
  width: 426px;
  height: 394px;
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
}

.box {
  width: 450px;
  height: 200px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
}

.title1 {
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}

.title2 {
  text-align: center;
  color: red;
  margin-top: 15px;
  font-size: 26px;
}

.input {
  position: absolute;
  margin-top: 20px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.title3 {
  text-align: center;
  color: gray;
  font-size: 14px;
  margin-top: 70px;
}

.title4 {
  text-align: center;
  color: #67c23a;
  font-size: 26px;
}

.matchNum {
  color: red;
  font-size: 30px;
}
</style>
